<template>
  <div>
    <load-profile></load-profile>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <b-button
                  :disabled="!$can('create', resource)"
                  :variant="!$can('create', resource) ? 'secondary' : 'primary'"
                  :to="{ name: `create-${route}` }"
                >
                  {{ $t("button.create") }}
                </b-button>
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          @destroy="destroy"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="true"
          :resource="resource"
          :route="route"
        >
          <template #cell(totalUsers)="data">
            {{
              $tc("field.personCount", data.item.totalUsers, {
                count: data.item.totalUsers,
              })
            }}
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap" v-if="!data.item.isDefault">
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: `update-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.update')"
                :disabled="!$can('update', resource)"
                :variant="!$can('update', resource) ? 'secondary' : 'primary'"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover
                :title="$t('button.delete')"
                :disabled="!$can('delete', resource)"
                :variant="!$can('delete', resource) ? 'secondary' : 'danger'"
                pill
                size="sm"
                @click="
                  $refs.table.destroy(data.item.id, data.index, data.item)
                "
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            <div v-else>
              <b-button
                v-b-tooltip.hover
                :title="$t('general.defaultRoleRule')"
                variant="secondary"
                pill
                size="sm"
              >
                <feather-icon icon="LockIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import NColumnVisibility from "@/components/NColumnVisibility";

const RoleRepository = Repository.get("role");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    vSelect,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.role;
      },
      set(value) {
        this.$store.commit("column/SET_ROLE_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      RoleRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      RoleRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch((error) => {});
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    const resource = "agent-role-permission";
    const route = "role-permission";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
